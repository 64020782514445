import { lazy, Suspense } from "react";
import { LinearProgress } from "@mui/material";
import { createBrowserRouter, Navigate } from "react-router-dom";

import lazyCatcher from "utils/lazyCatcher";

const Home = lazy(() => lazyCatcher(() => import(/* webpackPrefetch: true */ "pages/Home")));

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Suspense fallback={<LinearProgress sx={{ position: "absolute", top: 0, width: 1 }} />}>
        <Home />
      </Suspense>
    ),
  },
  { path: "*", element: <Navigate to="/" /> },
]);

export default router;
