import { createTheme, darken, lighten } from "@mui/material";

import palette from "./palette";

const header = { fontWeight: 500 };
const button = {
  borderRadius: 10,
  boxShadow: "none",
  textTransform: "capitalize",
  "&:active, &.active, &:hover": {
    boxShadow: "none",
  },
};

const theme = createTheme({
  palette,
  typography: {
    fontFamily: "Suisse, sans-serif",
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: palette.text.primary,
        },
        h1: {
          fontSize: 32,
          fontFamily: "Garamond, serif",
          ...header,
          fontWeight: 600,
          "@media (min-width:600px)": {
            fontSize: 50,
          },
        },
        h2: { fontSize: 48, ...header },
        h3: { fontSize: 36, ...header },
        h4: { fontSize: 24, ...header },
        h5: { fontSize: 20, ...header },
        h6: { fontSize: 18, ...header },
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          ...button,
          fontSize: 18,
          padding: "8px 30px",
          "&.MuiButton-sizeLarge": {
            padding: "16px 22px"
          },
        },
        containedSecondary: {
          color: palette.text.primary,
        }
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          ...button,
          "&.MuiToggleButton-standard": {
            backgroundColor: palette.white.main,
            borderColor: lighten(palette.text.primary, 0.4),
            borderRadius: 10,
            borderWidth: 2,
            color: palette.text.primary,
            fontSize: 16,
            padding: "10px",
            "&.MuiToggleButton-sizeLarge": {
              padding: "16px 22px"
            },
            "&:hover": {
              borderColor: palette.text.primary,
            },
            "&.Mui-selected": {
              backgroundColor: `${palette.primary.main}32`,
              borderColor: palette.primary.main,
              color: palette.primary.main,
              "&:hover": {
                backgroundColor: `${palette.primary.main}20`,
              }
            }
          },
          "&.MuiToggleButton-primary": {
            backgroundColor: palette.white.main,
            border: "none",
            borderRadius: 10,
            color: palette.text.primary,
            fontSize: 16,
            padding: "10px",
            "&.Mui-selected": {
              backgroundColor: palette.primary[500],
              color: palette.primary.main,
              "&:hover": {
                backgroundColor: `${palette.primary[500]}50`,
              }
            }
          },
          "&.MuiToggleButton-secondary": {
            backgroundColor: palette.white.main,
            borderColor: lighten(palette.text.primary, 0.4),
            borderRadius: 10,
            borderWidth: "2px",
            color: palette.text.primary,
            fontSize: 12,
            height: 58,
            "&:hover": {
              borderColor: palette.text.primary,
            },
            "&.Mui-selected": {
              backgroundColor: palette.primary.main,
              border: "none",
              color: palette.white.main,
              "&:hover": {
                backgroundColor: darken(palette.primary.main, 0.3),
              }
            }
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 30,
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 10,
          color: palette.text.primary,
          backgroundColor: palette.white.main,
        },
        notchedOutline: {
          borderWidth: "2px",
          // borderColor: palette.secondary[500],
          // "&.Mui-disabled": {
          //   borderColor: palette.secondary[200],
          // },
          // "&:hover": {
          //   borderColor: palette.secondary[700],
          // },
          "& legend": { width: 0 }
        },
        input: {
          padding: "14.5px",
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          // color: palette.secondary[500],
          fontSize: 16,
          fontWeight: 500,
          // "&.Mui-disabled": {
          //   color: palette.secondary[400],
          // },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        shrink: {
          transform: "translate(14px, 5px) scale(0.75)"
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-input": {
            padding: "26.5px 14px 8.5px 14px"
          }
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: 16,
          borderRadius: 8,
          backgroundColor: palette.background.secondary
        },
        bar: {
          borderRadius: 8,
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          alignItems: "flex-start",
          color: palette.primary.main,
          fontSize: 20,
          fontWeight: 500,
          maxWidth: "unset",
          textTransform: "capitalize",
          "&.Mui-selected": {
            backgroundColor: `${palette.text.primary}32`
          },
          "&.Mui-disabled": {
            color: palette.primary.main,
            opacity: 0.38,
          }
        },
        labelIcon: {
          minHeight: "unset",
          padding: "12px 16px",
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        vertical: {
          "& .MuiTabs-indicator": {
            width: 6,
          }
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 10,
        }
      }
    }
  }
});

export default theme;
