import { GlobalStyles, ThemeProvider } from "@mui/material";
import { RouterProvider } from "react-router-dom";

import palette from "assets/palette";
import theme from "assets/theme";
import router from "routes";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles
        styles={{
          body: { backgroundColor: palette.background.main }
        }}
      />
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
