import { colors } from "@mui/material";

const palette = {
  primary: {
    500: "#EED3CB",
    main: "#DB572F",
  },

  secondary: {
    main: "#EAE7E6",
  },

  text: {
    primary: "#968682",
  },

  green: {
    main: "#009D0E",
  },

  red: {
    main: "#9D0000",
    secondary: "#DF0000",
  },

  error: {
    main: "#9D0000",
  },

  background: {
    main: "#F9F4F2",
    secondary: "#D9D9D9",
  },

  disabled: {
    primary: "#8AB2B2",
    secondary: "#D2D2D2",
    text: "#7A7F7F",
    delete: "#A57878",
  },

  // accent color
  black: {
    main: colors.common.black,
    2: "#262B2B",
    3: "#373737",
    4: "#3E3E3E"
  },

  white: {
    main: colors.common.white,
    2: "#F6F6F6",
    3: "#DDDDDD",
    4: "#6f6f6f",
    dark: "#979797",
  },
};

export default palette;
