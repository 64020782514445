import { Button, Grid, Typography } from "@mui/material";

function FallbackComponent() {
  return (
    <Grid alignItems="center" container direction="column" justifyContent="center" spacing={2} sx={{ width: 1, height: 1 }}>
      <Grid item>
        <Typography variant="h5">Failed to Load the Page</Typography>
      </Grid>
      <Grid item>
        <Button variant="contained" onClick={() => window.location.reload()}>Reload</Button>
      </Grid>
    </Grid>
  );
}

export default function lazyCatcher(componentImport) {
  return new Promise((resolve) => {
    // try to import the component
    componentImport().then((component) => {
      resolve(component);
    }).catch((error) => {
      console.error("Component Failed Loading:", error);
      resolve({ default: FallbackComponent });
    });
  });
}
